import { Response, WithId } from "~/old-app/types/api";
import { Api } from "../api-store";
import {
  Blog,
  ContactUsSendMessageBody,
  PaymentMethod,
  Setting,
  StaticPage,
  StaticPagesQueryParams,
} from "./system-setting-types";
import { ProductsQueryParams } from "../product";

export const SystemSettingsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    staticPage: builder.query<Response<StaticPage>, string | undefined>({
      providesTags: (result, error, id) => [{ type: "static-page", id }],
      query: (id) => ({
        url: `/static-pages/${id}`,
        method: "GET",
      }),
    }),

    staticPages: builder.query<
      Response<StaticPage[]>,
      StaticPagesQueryParams | undefined
    >({
      providesTags: (result, error, params) => [
        { type: "static-pages", params },
      ],
      query: (params) => ({
        url: `/static-pages`,
        method: "GET",
        params,
      }),
    }),

    contactUs: builder.mutation<
      Response<ContactUsSendMessageBody>,
      ContactUsSendMessageBody
    >({
      query: (data) => ({
        url: `/contact-us`,
        method: "POST",
        data,
      }),
    }),

    settings: builder.query<Response<Setting>, void>({
      providesTags: ["settings"],
      query: () => ({
        url: `/settings`,
        method: "GET",
      }),
    }),
    blogs: builder.query<Response<Blog[]>, ProductsQueryParams | undefined>({
      providesTags: [],
      query: (params) => ({
        url: `/blogs`,
        method: "GET",
        params,
      }),

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { page, ...queryParams } = queryArgs as any;
        return {
          endpointName,
          ...queryParams,
        };
      },
      merge: (currentCache: Response<Blog[]>, newItems: Response<Blog[]>) => {
        currentCache.response.data.push(...newItems.response.data);
        return currentCache as Response<Blog[]>;
      },

      forceRefetch({ currentArg = {}, previousArg = {} }) {
        return Object.keys(currentArg).some(
          (key) => (currentArg as any)[key] !== (previousArg as any)[key]
        );
      },
    }),
    blog: builder.query<Response<Blog>, WithId<{}>>({
      providesTags: [],
      query: ({ id }) => ({
        url: `/blogs/${id}`,
        method: "GET",
      }),
    }),

    // /payment-methods/digital-product-methods get

    digitalProductMethods: builder.query<Response<PaymentMethod[]>, void>({
      providesTags: ["digital-product-methods"],
      query: () => ({
        url: `/payment-methods/digital-product-methods`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useDigitalProductMethodsQuery,
  useStaticPageQuery,
  useStaticPagesQuery,
  useSettingsQuery,

  useBlogQuery,
  useBlogsQuery,
} = SystemSettingsApi;

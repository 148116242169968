import { ApiOptions } from "~/old-app/types/api";
import {
  useDigitalProductMethodsQuery,
  useStaticPageQuery,
  useStaticPagesQuery,
  useSettingsQuery,
  useBlogQuery,
  useBlogsQuery,
} from "./system-setting-api";
import { StaticPagesQueryParams } from "./system-setting-types";
import { ProductsQueryParams } from "../product";
import { useState } from "react";

export const useDigitalProductMethods = (options?: ApiOptions) => {
  const { data, isLoading, isFetching } = useDigitalProductMethodsQuery(
    undefined,
    options
  );
  return {
    digitalProductMethods: data?.response?.data || [],
    isLoading,
    isFetching,
  };
};
export const useSettings = (options?: ApiOptions) => {
  const { data, isLoading, isFetching } = useSettingsQuery(undefined, options);
  return {
    settings: data?.response?.data,
    isLoading,
    isFetching,
  };
};
export const useBlogs = (props?: ProductsQueryParams, options?: ApiOptions) => {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useBlogsQuery(
    {
      ...props,
      page: props?.page || 1,
    },
    { skip: options?.skip }
  );

  const blogs = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total !== blogs.length;

  return {
    blogs: data?.response.data || [],
    total: data?.response.meta?.total || 0,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    meta: data?.response.meta,
  };
};
export const useStaticPages = (
  props?: StaticPagesQueryParams,
  options?: ApiOptions
) => {
  const { data, isLoading, isFetching } = useStaticPagesQuery(
    { ...props },
    options
  );

  const staticPages = data?.response.data || [];

  return {
    staticPages,
    isLoading,
    isFetching,
  };
};
export const useStaticPage = (id?: string) => {
  const { data, isLoading, isFetching } = useStaticPageQuery(id, { skip: !id });
  return {
    staticPage: data?.response.data,
    isLoading,
    isFetching,
  };
};
export const useBlog = (id?: string) => {
  const { data, isLoading, isFetching } = useBlogQuery({ id }, { skip: !id });
  return {
    blog: data?.response.data,
    isLoading,
    isFetching,
  };
};



